.scroller {
  &.hideScroll {
    &::-webkit-scrollbar {
      @apply h-0;
    }
  }

@apply snap-x overflow-x-scroll after:lg:hidden py-10 overflow-y-hidden mx-10 md:mx-8 lg:mx-10 md:gap-x-6 flex after:block before:block before:lg:hidden  before:flex-shrink-0 after:flex-shrink-0  scroll-smooth px-5;
}

.scroller div {
  @apply snap-center snap-always;
}

.navigation {
  @apply relative;
}

.navigation button {
  @apply absolute cursor-pointer;
}
